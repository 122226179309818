import React, { useState, useEffect } from "react";
import "./Login.scss";
import { TextField, Container, Box } from "@mui/material";
import { CustomButton } from "../CustomButton";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("isAuthenticated")) {
      navigate("/home");
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      email === process.env.REACT_APP_LOGIN_EMAIL &&
      password === process.env.REACT_APP_LOGIN_PASS
    ) {
      Cookies.set("isAuthenticated", "true", { expires: 3 }); // set a cookie that expires in 3 days
      navigate("./home");
      setError("");
    } else {
      setError("The email or password is incorrect!");
    }
  };

  return (
    <div
      className="wrapper"
      style={{ backgroundImage: `url(/images/nestle-form.jpg)` }}
    >
      <Container maxWidth="sm" sx={{ marginTop: "20vh" }}>
        <Box
          p={3}
          boxShadow={3}
          sx={{ backgroundColor: "#fff", borderRadius: "15px" }}
        >
          <form onSubmit={handleSubmit}>
            <TextField
              className="inputStyle"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              className="inputStyle"
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <p className="errorMessage">{error}</p>}
            <CustomButton
              variant="contained"
              type="submit"
              customStyle={{ width: "100px" }}
            >
              LOG IN
            </CustomButton>
          </form>
        </Box>
      </Container>
    </div>
  );
};
