import React from "react";
import "./HeroComponent.scss";
import heroImage from "../assets/heroImage.png";

export const HeroComponent = () => {
  return (
    <>
      <div className="heroContainer">
        <div className="heroText">
          <p className="welcome">WELCOME TO YOUR</p>
          <p style={{ color: "#EADCC7" }}>
            DATA <br /> STUDIO
          </p>
        </div>
        <div className="imageContainer">
          <img src={heroImage} alt="hero" />
        </div>
      </div>
    </>
  );
};
