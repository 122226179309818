import React from "react";
import "./Status.scss";

export const Status = ({ succes }) => {
  return (
    <div
      style={{
        backgroundColor: succes ? "#EBF9F1" : "#FBE7E8",
        color: succes ? "#1F9254" : "#A30D11",
      }}
      className="statusContainer"
    >
      {succes ? "Succes" : "Failure"}
    </div>
  );
};
