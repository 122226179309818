import React from "react";
import "./FeaturesCard.scss";
import { useNavigate } from "react-router-dom";

export const FeaturesCard = ({
  imageSrc,
  title,
  description,
  tabNumber,
  pathToNavigate,
}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/${pathToNavigate}`, {
      state: {
        tabNumber,
      },
    });
  };

  return (
    <div className="cardFrame" onClick={handleCardClick}>
      <img src={imageSrc} alt="card" />
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </div>
  );
};
