import { CustomButton } from "components/CustomButton";
import React from "react";
import { Link } from "react-router-dom";
import "./DashboardCard.scss";

export const DashboardCard = ({
  image,
  category,
  lastPublished,
  title,
  description,
  link,
}) => {
  return (
    <div className="cardContainer">
      <div className="imageContainer">
        <img src={image} alt="alt" />
      </div>
      <div className="textContainer">
        <div className="categoryContainer">
          <span>{category}</span>
          <span>Last published: {lastPublished}</span>
        </div>
        <p className="title">{title}</p>
        <p className="description">{description}</p>
        <div className="buttonContainer">
          <Link to={link} target="_blank">
            <CustomButton text="View more" variant={"contained"} />
          </Link>
        </div>
      </div>
    </div>
  );
};
