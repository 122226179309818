import React from "react";
import "./FeaturesList.scss";
import { FeaturesCard } from "./FeaturesCard";

export const FeaturesList = ({ features }) => {
  return (
    <div className="listContainer">
      <div className="title">
        <span className="explore">Explore</span>
        <span className="features">Features</span>
      </div>
      <div className="featuresListOuterContainer">
        <div className="featuresListContainer">
          {features.map((item) => (
            <FeaturesCard
              key={item.key}
              imageSrc={item.imageSrc}
              tabNumber={item.tabNumber}
              pathToNavigate={item.pathToNavigate}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
