import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FAQ.scss";
import { faqLandingPage } from "helpers/faq";

export const FAQ = () => {
  return (
    <div className="faqContainer">
      <p className="sectionTitle">Frequently Asked Questions</p>
      <div className="center">
        {faqLandingPage.map((item, index) => (
          <Accordion sx={{ width: "70%" }} key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ letterSpacing: "1.3px" }}>
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ letterSpacing: "1.3px" }}>
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};
