import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./DragAndDrop.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Guidlines from "./Guidlines";
import HistoryTabel from "./HistoryTabel";
import FileList from "./FileList";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DragInput } from "./DragInput";
import { CustomButton } from "./CustomButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import { DashboardCardList } from "./Card/DashboardCardList";
import { analysis } from "helpers/faq";
import { catalogueData } from "helpers/faq";

export const DragAndDrop = ({
  onFileChange,
  fileList,
  setFileList,
  handleUpload,
  loading,
  tabelData,
  setLoading,
}) => {
  const location = useLocation();
  const selectedTab = location.state?.tabNumber;
  const [value, setValue] = useState(selectedTab);
  const [downloadFilesNames, setDownloadFilesNames] = useState([]);
  const [filesToDownload, setFilesToDownload] = useState([]);
  const [downloadAction, setDownloadAction] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/blobs`
      );
      setDownloadFilesNames(response.data);
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onFileDrop = (e) => {
    const newFiles = Array.from(e.target?.files || []);

    if (newFiles.length > 0) {
      const filteredFiles = newFiles.filter((file) => {
        return (
          file.type === "text/csv" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      });

      if (filteredFiles.length !== newFiles.length) {
        alert("Only .csv and .xlsx files are allowed!");
      }

      const updatedList = [...fileList, ...filteredFiles];
      setFileList(updatedList);
      onFileChange({ files: updatedList });
    }
  };

  const fileRemove = (file) => {
    const updatedList = fileList.filter((f) => f !== file);
    setFileList(updatedList);
    onFileChange({ files: updatedList });
  };

  const handleCheck = (name) => {
    setFilesToDownload((prevState) => {
      if (prevState.includes(name)) {
        return prevState.filter((file) => file !== name);
      } else {
        return [...prevState, name];
      }
    });
  };

  const handleMultipleDownloads = async () => {
    console.log(filesToDownload);
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/downloadMultipleFiles`,
        { files: filesToDownload },
        { responseType: "blob" }
      );
      console.log("Response", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "files.zip");
      document.body.appendChild(link);
      link.click();
      setLoading(false);
      setFilesToDownload([]);
      setDownloadAction(!downloadAction);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSingleDownload = async (filename) => {
    console.log("Filename", filename);
    const fileToDownload = [];
    fileToDownload.push(filename);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/downloadMultipleFiles`, // Replace with your server route
        method: "POST",
        responseType: "blob", // Important for correct file download
        data: {
          files: fileToDownload,
          singleFile: true,
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // or any other filename you want
      document.body.appendChild(link);
      link.click();

      // Optional: remove the a element from body after download
      document.body.removeChild(link);
    } catch (error) {
      console.error("File download failed:", error);
    }
  };

  return (
    <>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <div className="tablist-center">
            <TabList
              variant="scrollable" // Add scrollable variant
              scrollButtons="auto"
              className="my-tabs"
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{ indicatorColor: "green" }}
            >
              <Tab label="Guidelines" value="1" className="tabStyle" />

              <Tab label="Upload" value="2" className="tabStyle" />
              <Tab label="Download" value="3" className="tabStyle" />
              <Tab label="Analysis" value="4" className="tabStyle" />
              <Tab label="Catalogue" value="5" className="tabStyle" />
            </TabList>
          </div>
        </Box>
        <TabPanel value="1">
          <Guidlines />
        </TabPanel>

        <TabPanel value="2">
          <DragInput
            onFileDrop={onFileDrop}
            fileList={fileList}
            fileRemove={fileRemove}
            loading={loading}
            handleUpload={handleUpload}
          />
          <HistoryTabel data={tabelData} />
        </TabPanel>
        <TabPanel value="3">
          <div className="container">
            <FileList
              downloadAction={downloadAction}
              files={downloadFilesNames}
              type="download"
              filesToDownload={filesToDownload}
              setFilesToDownload={setFilesToDownload}
              handleCheck={handleCheck}
              handleSingleDownload={handleSingleDownload}
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <CustomButton
                text="DOWNLOAD"
                variant="contained"
                onClick={handleMultipleDownloads}
                customStyle={{
                  marginTop: "20px",
                }}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel value="4">
          <DashboardCardList data={analysis} />
        </TabPanel>
        <TabPanel value="5">
          <DashboardCardList data={catalogueData} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default DragAndDrop;
