import React from "react";
import Button from "@mui/material/Button";

export const CustomButton = ({
  onClick,
  variant,
  text,
  customStyle,
  children,
  type = "button",
}) => {
  const outlineStyle = {
    borderColor: "white",
    color: "white",
    marginTop: "0",
    "&:hover": {
      borderColor: "white",
    },
  };

  return (
    <Button
      sx={{
        marginTop: "20px",
        backgroundColor: variant === "contained" ? "rgba(0, 0, 0, 1)" : "none",
        borderRadius: "5px",
        letterSpacing: "1.5px",
        padding: "5px 20px",
        "&:hover": {
          backgroundColor:
            variant === "contained" ? "rgba(0, 0, 0, 1)" : "none",
        },
        ...(variant === "outlined" ? outlineStyle : {}),
        ...customStyle,
      }}
      variant={variant}
      onClick={onClick}
      type={type}
    >
      {text}
      {children}
    </Button>
  );
};
