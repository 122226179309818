import React from "react";
import "./Guidlines.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { questions, dashboards } from "../helpers/faq";

const Guidlines = () => {
  return (
    <div className="guidlinesContainer">
      {questions.map((item, index) => (
        <Accordion sx={{ width: "70%" }} key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ letterSpacing: "1.3px" }}>
              {item.question}{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ letterSpacing: "1.3px" }}>
              {item.answer}
            </Typography>
            <ul className="detailsList">
              {item.answerDetails &&
                item.answerDetails.map((detail, index) => (
                  <li key={index}>{detail}</li>
                ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}

      <Accordion sx={{ width: "70%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography sx={{ letterSpacing: "1.3px" }}>
            How to upload a file?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ letterSpacing: "1.3px" }}>
            Use the upload UI to load one or multiple files into the data
            platform and track your upload history in the app.
          </Typography>
          <ol className="detailsList">
            <li>
              Drag and drop the files into the file drop zone or select the
              files from the file system.{" "}
            </li>
            <li>Review and examine the files previews. </li>
            <li>Click the upload button. </li>
            <li>Monitor the upload through the history view. </li>
          </ol>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ width: "70%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography sx={{ letterSpacing: "1.3px" }}>
            What files are expected per dashboard?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "15px", letterSpacing: "1.3px" }}>
            The expected files per dashboard are:
          </Typography>
          <div className="guidlinesContainer">
            {dashboards.map((item, index) => (
              <Accordion sx={{ width: "70%" }} key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>{item.dashboard} </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {item.files.map((file, index) => (
                    <div key={index} className="dashboardItem">
                      <p>File name: {file.name}</p>
                      <p>Type: {file.type}</p>
                      {index !== item.files.length - 1 && <hr />}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}{" "}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Guidlines;
