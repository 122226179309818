import React from "react";
import { DashboardCard } from "./DashboardCard";
import "./DashboardCardList.scss";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import uuid from "react-uuid";

export const DashboardCardList = ({ data }) => {
  const [filter, setFilter] = React.useState("");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <div className="filter">
        <Box sx={{ maxWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter}
              label="Category"
              onChange={handleChange}
            >
              <MenuItem value={"10"}>Ten</MenuItem>
              <MenuItem value={"20"}>Twenty</MenuItem>
              <MenuItem value={"30"}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
      <div className="dashboardCardContainer">
        {data.map((item) => (
          <DashboardCard
            key={uuid()}
            link={item.link}
            image={item.image}
            category={item.category}
            title={item.title}
            lastPublished={item.lastPublished}
            description={item.description}
          />
        ))}
      </div>
    </>
  );
};
