import React, { useState } from "react";
import "./DragAndDrop.scss";
import File from "./File";

export const FileList = ({
  files,
  fileRemove,
  type = "upload",
  filesToDownload,
  handleCheck,
  downloadAction,
  handleSingleDownload,
}) => {
  console.log("files", filesToDownload);
  return (
    <>
      {files.map((item, index) => (
        <File
          downloadAction={downloadAction}
          key={index}
          item={item}
          type={type}
          fileRemove={fileRemove}
          handleCheck={handleCheck}
          handleSingleDownload={handleSingleDownload}
        />
      ))}
    </>
  );
};

export default FileList;
