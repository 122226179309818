import React from "react";
import "./Home.scss";
import { HeroComponent } from "../components/LandingPageComponents/HeroComponent";
import { FeaturesList } from "../components/LandingPageComponents/FeaturesList";
import { FAQ } from "../components/LandingPageComponents/FAQ";
import { featureCards } from "helpers/faq";
import { Header } from "components/Header";

const Home = () => {
  return (
    <>
      <Header />
      <div className="whiteBackground">
        <div className="homeContainer">
          <HeroComponent />
          <FeaturesList features={featureCards} />
          <FAQ />
          {/* <Link className="mainButton" to="/main">
            <CustomButton text={"start"} variant="contained"></CustomButton>
          </Link> */}
        </div>
      </div>
    </>
  );
};

export default Home;
