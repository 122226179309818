import { Login } from "../components/Login/Login";
import React from "react";

export const Authentication = () => {
  return (
    <div>
      <Login />
    </div>
  );
};
