import React, { useState, useEffect } from "react";
import axios from "axios";
import { DragAndDrop } from "../components/DragAndDrop";
import Box from "@mui/material/Box";
import { Header } from "components/Header";
import "./Main.scss";


const Main = () => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabelData, setTabelData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          url: `/logs`,
          method: 'get',
          baseURL: process.env.REACT_APP_BACKEND_URL,
        }
        );
        setTabelData(response.data);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, [fileList]);

  const onFileChange = (files) => {
    console.log(files.files);
    return;
  };

  const handleUpload = async () => {
    if (!fileList.length) {
      console.log("There are no files");
    } else {
      const form = new FormData();
      fileList.map((file) => form.append("files", file));
      setLoading(true);
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/upload`,
        data: form,
        headers: {
          "Content-type": "multipart/form-data"
        },
      });

      console.log(response);
      setLoading(false);
      setFileList([]);
      const { statusOK, errors } = response.data.pipelinesResultSent;
      const statusOKString = statusOK
        .map((pipeline) => `${pipeline["Pipeline name"]}: ${pipeline.Status}`)
        .join("\n");

      const errorsString = errors.join("\n");

      let message = `Process completed\n${statusOKString}`;

      if (errorsString) {
        message += `\n\nErrors:\n${errorsString}`;
      }

      alert(message);
    }
  };

  return (
    <>
      <Header />
      <div className="App">
        <div className="uploadContainer">
          <Box
            className="boxStyle"
            sx={{
              width: {
                xs: "90vw", // For small screens
                sm: "90vw", // For medium screens and above
                md: "80vw", // For large screens and above
              },
            }}
          >
            <DragAndDrop
              onFileChange={onFileChange}
              fileList={fileList}
              setFileList={setFileList}
              loading={loading}
              setLoading={setLoading}
              handleUpload={handleUpload}
              tabelData={tabelData}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

export default Main;
