import React from "react";
import "./Header.scss";
import Logo from "./assets/logo-white.svg";
import { Link } from "react-router-dom";

export const Header = ({ user }) => {
  return (
    <div className="headerContainer">
      <Link to="/home">
        <img src={Logo} alt="" />
      </Link>
      <span>Data Studio</span>
      {/* <CustomButton variant="outlined" text={"Log out!"} /> */}
    </div>
  );
};
