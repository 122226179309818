import { React, useState, useEffect } from "react";
import Eye from "./assets/eye.svg";
import Download from "./assets/download.svg";
import "./File.scss";
import Modal from "react-modal";
import NotChecked from "./assets/checkbox.svg";
import Checked from "./assets/checkbox--checked.svg";
import * as XLSX from "xlsx";
import { dateFormatter } from "../helpers/formatter";

export const File = ({
  item,
  fileRemove,
  type,
  handleCheck,
  downloadAction,
  handleSingleDownload,
}) => {
  const [fileData, setFileData] = useState({
    csvContent: false,
    modalOpen: false,
    sheetData: [],
  });
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false); // Reset the checkbox state on download action
  }, [downloadAction]);

  const handleOpenModal = async () => {
    const file = item;
    const fileName = type === "upload" ? item.name : item;

    if (fileName.includes(".csv")) {
      const text = await file.text();

      // loop through each sheet in the CSV file
      const rows = text.trim().split("\n");
      let headers = rows[0].split(";");
      if (headers[0].includes("\t")) {
        headers = headers[0].split("\t");
      } else if (headers[0].includes("\r")) {
        headers = headers[0].split("\r");
      }
      let data = rows.slice(1).map((row) => row.split(";"));
      data = rows.slice(1).map((row) => {
        if (row.includes("\t")) {
          return row.split("\t");
        } else return row.split(";");
      });

      const sheetData = [
        {
          sheetName: "Sheet 1",
          data: data,
          headers: headers,
        },
      ];

      setFileData((prevState) => ({
        ...prevState,
        csvContent: true,
        sheetData: sheetData,
        modalOpen: true,
      }));
    } else if (fileName.includes(".xlsx")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;

        const workbook = XLSX.read(data, { type: "binary", cellDates: true });

        const allSheetData = [];

        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const csvData = XLSX.utils.sheet_to_csv(worksheet, {
            FS: ";",
            RS: "\n",
          });
          const rows = csvData.trim().split("\n");
          const dataArr = rows
            .map((row) => row.split(";"))
            .filter((row) => row.some((cell) => cell.trim() !== ""));

          console.log(`data for sheet ${sheetName}:`, dataArr);

          allSheetData.push({
            sheetName: sheetName,
            data: dataArr,
          });
        });

        setFileData((prevState) => ({
          ...prevState,
          sheetData: allSheetData,
          modalOpen: true,
          csvContent: false,
        }));
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleCloseModal = () => {
    setFileData((prevState) => ({
      ...prevState,
      csvContent: "",
      sheetData: [],
      modalOpen: false,
    }));
  };

  const renderTable = () => {
    if (fileData.sheetData.length > 0) {
      return (
        <>
          {fileData.sheetData.map((sheet) => (
            <div key={sheet.sheetName}>
              <h2>{sheet.sheetName}</h2>
              <table>
                <thead>
                  <tr>
                    {fileData.csvContent === false
                      ? sheet.data[0].map((header, index) => (
                          <th key={index}>{header}</th>
                        ))
                      : sheet.headers.map((header, index) => (
                          <th key={index}>{header}</th>
                        ))}
                  </tr>
                </thead>
                <tbody>
                  {sheet.data.slice(1).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>
                          {" "}
                          {cell instanceof Date
                            ? dateFormatter.format(cell)
                            : cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </>
      );
    }
  };

  return (
    <div className="drop-file-preview__item">
      <div className="drop-file-preview__item__info">
        <div className="file-name" title={item.name}>
          {type === "upload" ? item.name : item}
        </div>
      </div>
      <div className="image-container">
        {type === "upload" && (
          <img src={Eye} alt="preview icon" onClick={handleOpenModal} />
        )}
        {type === "upload" && (
          <span
            className="drop-file-preview__item__del"
            onClick={() => fileRemove(item)}
          >
            x
          </span>
        )}
        {type === "download" && (
          <>
            <img
              className="downloadIcon"
              src={Download}
              alt="download"
              onClick={() => handleSingleDownload(item)}
            />
            <img
              onClick={() => {
                setCheck((prevState) => !prevState);
                handleCheck(item);
              }}
              className="downloadIcon"
              src={check ? Checked : NotChecked}
              alt="checkbox"
            />
          </>
        )}
      </div>
      <Modal isOpen={fileData.modalOpen} onRequestClose={handleCloseModal}>
        {renderTable()}
      </Modal>
    </div>
  );
};

export default File;
