export const questions = [
  {
    question: "What is Nespresso Data App?",
    answer: `Nespresso Data App is an application specifically designed for our
          business users to interact with our data platform. It allows you to
          integrate your files seamlessly into our storage medium. It also
          enables you to extract valuable insights from reports to meet your
          business intelligence needs.`,
    answerDetails: [],
  },
  {
    question: "What are the key features of the app? ",
    answer: `The key features offered by the app are:`,
    answerDetails: [
      "Authentication",
      "Upload, for files upload to the data platform",
      "Download, for routine reports download",
      "Dashboards, for a holistic view of the dashboards",
    ],
  },
  {
    question: "What are the dashboard types supported by the app?",
    answer: `The dashboards currently supported by the app are:`,
    answerDetails: [
      "Combined Dashboards (Graphs & Tables)",
      "Just Tables Dashboards",
      "Just Graphs Dashboards",
    ],
  },
  {
    question: "What are the file types supported by the app?",
    answer: `The file types currently supported by the app are:`,
    answerDetails: ["Excel Files (.xlsx) ", "CSV Files (.csv) "],
  },
];

export const dashboards = [
  {
    dashboard: "Amazon_Dashboard_V19",
    files: [
      { name: "stackline_NUSA_brand_recoded", type: "CSV" },
      {
        name: "stackline_asin_master_lookup",
        type: "CSV",
      },
    ],
  },
  {
    dashboard: "B2C AMZ Monthly Report",
    files: [
      { name: "stackline_NUSA_brand_recoded", type: "CSV" },
      {
        name: "stackline_asin_master_lookup",
        type: "CSV",
      },
      {
        name: "vd_coffee_output_recoded",
        type: "CSV",
      },
      {
        name: "YYYY Nespresso All Coffee Total Report (NN)",
        type: "CSV",
      },
    ],
  },
  {
    dashboard: "CKLR_V1",
    files: [{ name: "vd_coffee_output_recoded ", type: "CSV" }],
  },
  {
    dashboard: "Coffee Segmentation",
    files: [{ name: "Coffee Mapping", type: "XLSX" }],
  },
  {
    dashboard: "Compatible Deep Dive Visuals",
    files: [
      { name: "stackline_NUSA_brand_recoded", type: "CSV" },
      { name: "stackline_asin_master_lookup", type: "CSV" },
      { name: "vd_coffee_output_recoded", type: "CSV" },
      { name: "YYYY Nespresso All Coffee Total Report (NN)", type: "CSV" },
    ],
  },
  {
    dashboard: "master_sales_database_v1",
    files: [
      { name: "stackline_NUSA_brand_recoded", type: "CSV" },
      { name: "vd_coffee_output_recoded", type: "CSV" },
      { name: "YYYY Nespresso All Coffee Total Report (NN) ", type: "CSV" },
      { name: "stackline_NUSA_brand_recoded ", type: "CSV" },
    ],
  },
  {
    dashboard: "New Holistic Dashboard v2 ",
    files: [
      { name: "stackline_NUSA_brand_recoded ", type: "CSV" },
      { name: "stackline_asin_master_lookup ", type: "CSV" },
      { name: "vd_coffee_output_recoded ", type: "CSV" },
      { name: "YYYY Nespresso All Coffee Total Report (NN)", type: "CSV" },
    ],
  },
  {
    dashboard: "Order penetration - Final ",
    files: [{ name: "vd_coffee_output_recoded", type: "CSV" }],
  },
  {
    dashboard: "Weekly Sales",
    files: [
      { name: "DIRECT_Forecast_yyyymm ", type: "XLSX" },
      { name: "XXXX_Forecast_yyyymm ", type: "XLSX" },
      { name: "TRADE_Forecast_yyyymm ", type: "XLSX" },
      { name: "Trade_ONLINE_OFFLINE_yyyymm ", type: "XLSX" },
    ],
  },
];

export const analysis = [
  {
    image: "/images/image 14.png",
    category: "Customer Analytics",
    title: "Dars store analysis",
    lastPublished: "22/12/2022",
    link: "https://www.google.com/",
    description:
      "As the first thing people see when they visit your website, a hero section is the area that immediately shows up on the screen under",
  },
  {
    image: "/images/image 14.png",
    category: "Customer Analytics",
    title: "Dars store analysis",
    lastPublished: "22/12/2022",
    description:
      "As the first thing people see when they visit your website, a hero section is the area that immediately shows up on the screen under",
  },
  {
    image: "/images/image 14.png",
    category: "Customer Analytics",
    title: "Dars store analysis",
    lastPublished: "22/12/2022",
    description:
      "As the first thing people see when they visit your website, a hero section is the area that immediately shows up on the screen under",
  },
  {
    image: "/images/image 14.png",
    category: "Customer Analytics",
    title: "Dars store analysis",
    lastPublished: "22/12/2022",
    description:
      "As the first thing people see when they visit your website, a hero section is the area that immediately shows up on the screen under",
  },
];

export const faqLandingPage = [
  {
    question: "What type of dashboards can I access?",
    answer:
      "You can access all the centralized combined, graphs and tables Power BI dashboards you have permission for.",
  },
  {
    question: "What type of files can I upload?",
    answer:
      "You can upload two types of files: Excel Files (.xlsx) and  CSV Files (.csv).",
  },
  {
    question: "Where can I upload the files?",
    answer:
      "You can upload your files by dragging and dropping them into the designated File Drop Zone or you can retrieve files from your computer's file system using the traditional file selection method.",
  },
  {
    question: "What are the 'Just Tables' dashboards?",
    answer:
      "The Just Tables dashboards provide a streamlined and organized display of your data in table format.",
  },
];

export const featureCards = [
  {
    key: 1,
    imageSrc: "/images/guidlinesCardIcon.svg",
    tabNumber: "1",
    pathToNavigate: "main",
    title: "Explore the guidelines",
    description:
      "Check out the tips and steps on how to navigate and use the Data Studio.",
  },
  {
    key: 2,
    imageSrc: "/images/uploadIcon.svg",
    tabNumber: "2",
    pathToNavigate: "main",
    title: "Upload your input files",
    description: "Upload files required for your dashboards.",
  },
  {
    key: 3,
    imageSrc: "/images/downloadCardIcon.svg",
    tabNumber: "3",
    pathToNavigate: "main",
    title: "Download ad-hoc reports",
    description:
      "Browse and download your most frequently used queries and reports.",
  },
  {
    key: 4,
    imageSrc: "/images/analyzes--data.svg",
    tabNumber: "4",
    pathToNavigate: "main",
    title: "Analysis",
    description: "View the ad-hoc analysis of your business vertical.",
  },
  {
    key: 5,
    imageSrc: "/images/catalogueIcon.png",
    tabNumber: "5",
    pathToNavigate: "main",
    title: "Catalogue",
    description:
      "Consult the catalogue of Dashboard currently in production, and the latest Analyses.",
  },
];

export const catalogueData = [
  {
    image: "/images/image 6.png",
    category: "Customer Analytics",
    title: "Coffee Segmentation Dashboard",
    lastPublished: "22/12/2022",
    link: "https://www.google.com/",
    description:
      "As the first thing people see when they visit your website, a hero section is the area that immediately shows up on the screen under your logo and menu. ",
  },
  {
    image: "/images/image 6.png",
    category: "Customer Analytics",
    title: "Coffee Segmentation Dashboard",
    lastPublished: "22/12/2022",
    link: "https://www.google.com/",
    description:
      "As the first thing people see when they visit your website, a hero section is the area that immediately shows up on the screen under your logo and menu. ",
  },
  {
    image: "/images/image 6.png",
    category: "Customer Analytics",
    title: "Coffee Segmentation Dashboard",
    lastPublished: "22/12/2022",
    link: "https://www.google.com/",
    description:
      "As the first thing people see when they visit your website, a hero section is the area that immediately shows up on the screen under your logo and menu. ",
  },
];
