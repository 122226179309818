import FileList from "./FileList";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomButton } from "./CustomButton";
import "./DragInput.scss";
import React from "react";

export const DragInput = ({
  onFileDrop,
  fileList,
  fileRemove,
  loading,
  handleUpload,
}) => {
  return (
    <div className="container">
      <div className="drop-file-input">
        <p>Click or Drag & Drop your files here!</p>
        <input
          className="DragAndDropInput"
          type="file"
          value=""
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          multiple
          onChange={onFileDrop}
        />{" "}
      </div>
      {fileList.length > 0 ? (
        <div className="drop-file-preview">
          <p className="drop-file-preview__title">Ready to upload</p>
          <FileList fileRemove={fileRemove} files={fileList} />
        </div>
      ) : null}

      {loading ? (
        <CircularProgress />
      ) : (
        <CustomButton
          text="UPLOAD"
          variant="contained"
          onClick={handleUpload}
          customStyle={{
            marginTop: "20px",
          }}
        />
      )}
    </div>
  );
};
